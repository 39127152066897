import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { IListItem } from 'data-transfers/dto'

import { useList } from 'hooks/useList'
import useMediaQuery from 'hooks/useMediaQuery'

import { minWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

import { IMultiListItemsAutocomplete } from './types'

const useMultiListItemSelect = ({
  selectedItems,
  type
}: Pick<IMultiListItemsAutocomplete, 'selectedItems' | 'type'>) => {
  const isDesktop = useMediaQuery(minWidth(RESPONSIVE.lg))
  const { locale: languageCode } = useRouter()
  const { data: items, isLoading } = useList({ languageCode, type })

  const filterFn = useCallback((item: IListItem, inputValue?: string) => {
    const lowerCasedInputValue = inputValue?.toLowerCase()

    return (
      !lowerCasedInputValue ||
      item.value.toLowerCase().includes(lowerCasedInputValue)
    )
  }, [])

  const options = useMemo(
    () =>
      items?.filter(
        (({ id }) =>
          !selectedItems.some((selectedItem) => selectedItem.id === id)) ?? []
      ) as IListItem[],
    [selectedItems, items]
  )

  return { options, filterFn, isDesktop, isLoading }
}

export default useMultiListItemSelect
